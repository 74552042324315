

var endpoint = '/patient'
export default class PatientService {

  constructor(axios){
    this.axios = axios
  }

  list(params) {
    return this.axios.get(`${endpoint}/`, { params })
  }

  listPaymenttypes(){
    return this.axios.get(`${endpoint}/paymenttypes/`)
  }

  listByName(name, params) {
    return this.axios.get(`${endpoint}/name/${name}`, { params })
  }

  save(patient) {
    
    if (patient.id) {
      return this.axios.put(`${endpoint}/${patient.id}`, patient)
    } else {
      return this.axios.post(`${endpoint}/`, patient)
    }
  }

  delete(id) {
    return this.axios.delete(`${endpoint}/${id}`)
  }

  get(id) {
    return this.axios.get(`${endpoint}/${id}`)
  }
}
