

var endpoint = '/paymenttype'
export default class PaymentTypeService {

  constructor(axios){
    this.axios = axios
  }

  list(params) {
    return this.axios.get(`${endpoint}/`, { params })
  }
  

  save(paymentType) {
    if (paymentType.id) {
      return this.axios.put(`${endpoint}/${paymentType.id}`, paymentType)
    } else {
      return this.axios.post(`${endpoint}/`, paymentType)
    }
  }

  delete(id) {
    return this.axios.delete(`${endpoint}/${id}`)
  }

  get(id) {
    return this.axios.get(`${endpoint}/${id}`)
  }
}
